import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

// AXIOS
// https://www.digitalocean.com/community/tutorials/react-axios-react-es

// ICONOS MATERIAL UI
// https://material-ui.com/components/material-icons/

// VALIDADION DE FORM con FORMIK TS
// https://codesandbox.io/s/915qlr56rp?file=/src/index.tsx
// https://github.com/simpletut/React-Formik-Material-UI-Booking-Form-Template/blob/main/src/App.js
// https://github.com/vikas62081/YT/blob/basicFormValidation/src/components/index.js